export default {
    // api: 'https://thanos-backend-corporativa.softys-leadcorporate.com/',
    api: 'https://backend-thanos.grow-solutions.com/',
    // api: 'http://127.0.0.1:8000/',
    basename: '/',
    apiS3: 'https://express-backend-spider.grow-corporate.com/',
    // apiS3: 'http://127.0.0.1:3001/',
    nombreSistema : 'Thanos App',
    nombreSistemaHo : 'Thanos',
    nombreCliente : 'Softys Perú'
};
